@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,400;1,500&family=Inter:wght@300;400;500;600&family=Space+Grotesk:wght@400;500;600;700&display=swap");

.navbar {
  background-color: #121418 !important;
  border-radius: 0.5rem;
  margin-top: 2rem;
}

.navbar:hover {
  background-color: #201335 !important;
  border-radius: 0.3rem;
  transition: 0.3s ease-in-out;
}

.navbar .navbar-brand {
  font-family: 'Space Grotesk', sans-serif;
  color: #e9e6e4 !important;
  font-size: 24px;
}

.navbar .nav-link {
  font-family: 'Inter', sans-serif;
  color: #e9e6e4 !important;
}

.navbar .nav-link.active {
  color: #c3d1ff !important;
  transition: 0.3s ease-in-out;
}
