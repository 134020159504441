.sources-page {
  max-width: 80vw;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
  padding: 40px 4px;
  margin: 0 auto;
}

.sources-page h1.center {
  font-family: 'Space Grotesk', sans-serif;
  color: #121418;
  text-align: center;
}

.sources-page .mb-3 {
  margin-bottom: 20px;
}

.sources-page .card-animation {
  background-color: #e9e6e4;
  padding: 20px;
  border: 1px solid #94918f;
  margin-bottom: 20px;
  flex: 0 0 calc(33.33% - 20px);
  min-height: 100%;
}

.sources-page .card-animation .card-title {
  font-size: 22px;
  font-family: 'Space Grotesk', sans-serif;
  color: #121418;
}

.sources-page .card-animation .card-text {
  font-family: 'Inter', sans-serif;
  color: #161419;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sources-page .card-animation .btn-primary {
  font-family: 'Inter', sans-serif;
  color: #e6e5e7;
  text-decoration: none;
  background-color: #121418;
  border: 1px solid #121418;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.sources-page .card-animation .btn-primary:hover {
  background-color: #201335;
  border: 1px solid #201335;
  transition: 0.3s ease-in-out;
}

/*
@media screen and (max-width: 768px) {
  .sources-page {
    padding: 0 2em;
  }
}
*/

/* Buttons */

.sources-page .form-row {
  align-items: flex-end;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.sources-page .form-row .form-group:first-child {
  flex: 2 1 0%;
}

.sources-page .form-row .form-group:nth-child(2) {
  flex: 3 1 0%;
}

.sources-page .form-row .btn-primary {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 70px;
  background-color: #201335;
  height: 2.5rem;
  border: 1px solid #201335;
  transition: 0.3s ease-in-out;
}

.form-group {
  margin-bottom: 0;

  input {
    border: 1px solid transparent;
    height: 2.5rem;
  }
}

.form-control {
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  border: 1px solid #201335;
  box-shadow: 0 0 0 0.2rem rgba(32, 19, 53, 0.25);
}